<template>
  <div class="box">
    <div class="logoImg">
      <img src="../assets/img/logo.png" alt="" />
    </div>
    <div class="btn">
      <img
        src="../assets/img/xiazai.png"
        v-if="isandriod"
        alt=""
        @click="clickBtnAn"
      />
      <img
        src="../assets/img/xiazai.png"
        v-if="isios"
        alt=""
        @click="clickBtnIOS"
      />
    </div>
    <div v-if="isweixin" class="isWeixinOpen">
      <img src="../assets/img/zao.png" alt="" />
    </div>
    <van-dialog v-model="dialogShow" title="温馨提示">
      <div class="titleInfo">
        <p>iOS版本正在开发中...</p>
        <p>敬请期待！</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { getDownUrl } from '../api/index'

export default {
  name: 'download',
  data() {
    return {
      isios: false,
      isandriod: false,
      andriod: '',
      ios: '',
      isweixin: false,
      dialogShow: false, //提示
      message: ''
    }
  },
  created() {
    var ua = navigator.userAgent.toLowerCase()
    var isWeixin = ua.indexOf('micromessenger') != -1
    if (isWeixin) return (this.isweixin = true)
    this.appSource()
  },
  methods: {
    // 剪贴板
    dataProcessing(val) {
      // this.message = '#' + val + '#'
      this.message = val
    },
    // 剪贴板
    doCopy(val) {
      this.dataProcessing(val)
      this.$copyText(this.message).then(
        function (e) {
          // alert('Copied')
          // that.onCopy(e.text)
          console.log(e, 9999)
          console.log(e.text, 9999)
        },
        function (e) {
          // alert('Can not copy')
          console.log(e, 8888)
          console.log(e.text, 888888)
        }
      )
    },
    async clickBtnAn() {
      if (this.$route.query.invite_code) {
        await this.doCopy(this.$route.query.invite_code)
      }
      window.location.href = this.andriod
    },
    async clickBtnIOS() {
      if (this.$route.query.invite_code) {
        await this.doCopy(this.$route.query.invite_code)
      }
      // window.location.href = this.ios;
      // this.dialogShow = true;
      window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
    },
    async appSource() {
      let res = await getDownUrl()
      if (res.data.code != 200) return
      this.andriod = res.data.data.andriod_url
      this.ios = res.data.data.ios_url
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        this.isios = true
      } else {
        this.isandriod = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100vw;
  height: 21.6rem;
  background-image: url('../assets/img/download.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100%;
}
.logoImg {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  img {
    width: 2.2rem;
    object-fit: contain;
    margin-right: 0.2rem;
    margin-top: 0.2rem;
  }
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  img {
    width: 4rem;
    height: auto;
  }
}
.isWeixinOpen {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  background: rgba(#000, 0.4);
  top: 0;
  display: flex;
  justify-content: flex-end;
  img {
    width: 7rem;
    height: 7rem;
    margin-right: 0.2rem;
  }
}
.van-dialog {
  width: 6rem;
  height: 3.5rem;
  /deep/ .van-hairline--top {
    height: 0.9rem;
    margin-top: 0.6rem;
  }
  /deep/ .van-button {
    height: 100%;
  }
  .titleInfo {
    margin: 0.4rem 0 0.1rem;
  }
  P {
    width: 100%;
    text-align: center;
    font-size: 0.34rem;
    margin-top: 0.1rem;
  }
}
</style>
